<template>
  <div>
    <div
      id="input-component-container"
      class="border flex items-center rounded shadow-sm  focus-within:outline-none focus-within:ring-4 focus-within:shadow"
      :class="{
        'border-red-200 shadow-red-300/35 focus-within:ring-red-300 focus-within:border-red-600 focus-within:shadow-red-300/35': errors.length,
        'border-slate-200 shadow-slate-300/35 focus-within:ring-blue-300 focus-within:border-blue-600 focus-within:shadow-blue-300/35': !errors.length,
      }"
    >
      <label
        v-if="icon"
        class="leading-0 pl-3"
        :for="id"
      >
        <Icon
          :name="icon"
          class="leading-0"
          :class="{
            'text-red-200': errors.length,
            'text-slate-200': !errors.length,
          }"
        />
      </label>

      <input
        :id="id"
        class="block border-none px-3 py-2 rounded w-full focus:ring-0 focus:outline-none"
        :class="{
          'placeholder:text-red-400': errors.length,
          'placeholder:text-slate-400': !errors.length,
        }"
        :value="modelValue"
        v-bind="attrs"
        @input="updateValue"
      >
    </div>

    <template v-if="showErrorMessages">
      <div
        v-for="(error, index) in errors"
        id="input-component-errors-container"
        :key="index"
        class="mt-2"
      >
        <div class="text-red-500 text-sm">
          {{ error }}
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
  const { id, icon, modelValue } = defineProps({
    id: {
      type: String,
      default: '',
    },

    icon: {
      type: String,
      default: '',
    },

    errors: {
      type: Array,
      default: [],
    },

    showErrorMessages: {
      type: Boolean,
      default: true,
    },

    modelValue: {
      type: String,
      default: '',
    },
  });

  defineOptions({
    inheritAttrs: false,
  })

  const attrs = useAttrs();
  const emit = defineEmits();

  function updateValue(event: any) {
    emit('update:modelValue', event.target.value);
  }
</script>
